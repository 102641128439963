var render = function render(){
  var _vm$giftcard, _vm$giftcard$images, _vm$giftcard2, _vm$giftcard3, _vm$giftcard4, _vm$giftcard5, _vm$giftcard6, _vm$giftcard6$price, _vm$giftcard6$price$f, _vm$giftcard7, _vm$giftcard7$salePri, _vm$giftcard7$salePri2, _vm$me, _vm$giftcard8, _vm$giftcard9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "giftcard-view"
  }, [_c('v-row', {
    staticClass: "giftcard-info"
  }, [_c('v-col', {
    staticClass: "pa-3 pa-md-6",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-responsive', {
    staticClass: "elevation-3",
    attrs: {
      "width": "100%",
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-img', {
    attrs: {
      "src": (_vm$giftcard = _vm.giftcard) === null || _vm$giftcard === void 0 ? void 0 : (_vm$giftcard$images = _vm$giftcard.images) === null || _vm$giftcard$images === void 0 ? void 0 : _vm$giftcard$images[0],
      "width": "100%",
      "height": "auto",
      "contain": ""
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-3 pa-md-6",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "giftcard-name"
  }, [_vm._v(_vm._s((_vm$giftcard2 = _vm.giftcard) === null || _vm$giftcard2 === void 0 ? void 0 : _vm$giftcard2.name))]), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_c('b', [_vm._v("판매가")])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [(_vm$giftcard3 = _vm.giftcard) !== null && _vm$giftcard3 !== void 0 && _vm$giftcard3.price && ((_vm$giftcard4 = _vm.giftcard) === null || _vm$giftcard4 === void 0 ? void 0 : _vm$giftcard4.price) != ((_vm$giftcard5 = _vm.giftcard) === null || _vm$giftcard5 === void 0 ? void 0 : _vm$giftcard5.salePrice) ? _c('span', {
    staticClass: "red--text text-decoration-line-through mr-2"
  }, [_vm._v(_vm._s((_vm$giftcard6 = _vm.giftcard) === null || _vm$giftcard6 === void 0 ? void 0 : (_vm$giftcard6$price = _vm$giftcard6.price) === null || _vm$giftcard6$price === void 0 ? void 0 : (_vm$giftcard6$price$f = _vm$giftcard6$price.format) === null || _vm$giftcard6$price$f === void 0 ? void 0 : _vm$giftcard6$price$f.call(_vm$giftcard6$price)) + " 원")]) : _vm._e(), _c('span', [_c('b', [_vm._v(_vm._s((_vm$giftcard7 = _vm.giftcard) === null || _vm$giftcard7 === void 0 ? void 0 : (_vm$giftcard7$salePri = _vm$giftcard7.salePrice) === null || _vm$giftcard7$salePri === void 0 ? void 0 : (_vm$giftcard7$salePri2 = _vm$giftcard7$salePri.format) === null || _vm$giftcard7$salePri2 === void 0 ? void 0 : _vm$giftcard7$salePri2.call(_vm$giftcard7$salePri)))]), _vm._v(" 원")])])], 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "구매수량",
      "placeholder": " ",
      "hide-details": "",
      "suffix": "개",
      "maxlength": "5"
    },
    on: {
      "input": function ($event) {
        _vm.$nextTick(function (_) {
          _vm.quantity = _vm.quantity.replace(/[^0-9]/g, '');
          _vm.quantity = 200 < _vm.quantity ? 200 : _vm.quantity;
        });
      }
    },
    model: {
      value: _vm.quantity,
      callback: function ($$v) {
        _vm.quantity = $$v;
      },
      expression: "quantity"
    }
  }), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_c('b', [_vm._v("구매금액")])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_c('b', [_vm._v(_vm._s(_vm.saleAmount.format()))]), _vm._v(" 원")])])], 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "pink--text"
  }, [_c('b', [_vm._v("보유포인트")])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.me ? _c('span', [_c('b', [_vm._v(_vm._s((((_vm$me = _vm.me) === null || _vm$me === void 0 ? void 0 : _vm$me.point) || 0).format()))]), _vm._v(" 원")]) : _c('v-btn', {
    attrs: {
      "dark": "",
      "elevation": "0",
      "color": "red darken-1"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("로그인")])], 1)], 1), _vm.me ? _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("나의 포인트충전 계좌")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("[우리은행] " + _vm._s(_vm.me.vaccount.accountNumber))])])], 1) : _vm._e(), _vm.me ? _c('v-divider', {
    staticClass: "my-2"
  }) : _vm._e(), _vm.me ? _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "caption",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v("※위의 계좌로 입금시 1~3분 내로 포인트가 자동으로 충전됩니다.")]), _c('div', [_vm._v("※단 발급된 가상계좌 이외 고객님의 실수로 타 계좌 입금시 반영이 되지 않습니다.")])])], 1) : _vm._e(), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-spacer'), _c('v-col', [_c('v-btn', {
    attrs: {
      "width": "100%",
      "tile": "",
      "dark": "",
      "x-large": "",
      "color": "grey darken-3"
    },
    on: {
      "click": _vm.order
    }
  }, [_vm._v("주문요청")])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "giftcard-content mt-lg-6",
    attrs: {
      "id": "giftcard-content"
    }
  }, [_c('v-divider'), _c('v-layout', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-btn', {
    staticClass: "text-center",
    attrs: {
      "width": "50%",
      "href": "#giftcard-content",
      "x-large": "",
      "tile": "",
      "outlined": ""
    }
  }, [_vm._v("상품정보")]), _c('v-btn', {
    staticClass: "text-center",
    attrs: {
      "width": "50%",
      "href": "#giftcard-notification",
      "x-large": "",
      "tile": "",
      "text": ""
    }
  }, [_vm._v("안내사항")])], 1), _c('v-divider', {
    staticClass: "mb-3"
  }), _c('p', {
    staticClass: "contain",
    domProps: {
      "innerHTML": _vm._s((_vm$giftcard8 = _vm.giftcard) === null || _vm$giftcard8 === void 0 ? void 0 : _vm$giftcard8.content)
    }
  })], 1), _c('div', {
    staticClass: "giftcard-notification mt-6",
    attrs: {
      "id": "giftcard-notification"
    }
  }, [_c('v-divider'), _c('v-layout', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-btn', {
    staticClass: "text-center",
    attrs: {
      "width": "50%",
      "href": "#giftcard-content",
      "x-large": "",
      "tile": "",
      "text": ""
    }
  }, [_vm._v("상품정보")]), _c('v-btn', {
    staticClass: "text-center",
    attrs: {
      "width": "50%",
      "href": "#giftcard-notification",
      "x-large": "",
      "tile": "",
      "outlined": ""
    }
  }, [_vm._v("안내사항")])], 1), _c('v-divider', {
    staticClass: "mb-3"
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s((_vm$giftcard9 = _vm.giftcard) === null || _vm$giftcard9 === void 0 ? void 0 : _vm$giftcard9.notification)
    }
  })], 1), _c('login-dialog', {
    ref: "loginDialog"
  }), _c('complete-dialog', {
    ref: "completeDialog"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }