var render = function render(){
  var _vm$giftcard$thumb, _vm$giftcard$thumb2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "color": "transparent",
      "elevation": "0",
      "to": `/gshop/giftcards/${_vm.giftcard._id}`
    }
  }, [_c('div', {
    staticStyle: {
      "border": "1px solid #e0e0e0"
    }
  }, [_c('v-img', {
    attrs: {
      "src": ((_vm$giftcard$thumb = _vm.giftcard.thumb) === null || _vm$giftcard$thumb === void 0 ? void 0 : _vm$giftcard$thumb.path) || ((_vm$giftcard$thumb2 = _vm.giftcard.thumb) === null || _vm$giftcard$thumb2 === void 0 ? void 0 : _vm$giftcard$thumb2.src)
    }
  })], 1), _c('div', {
    staticClass: "mt-4 gift-title text-left",
    staticStyle: {
      "text-overflow": "ellipsis",
      "overflow": "hidden",
      "display": "-webkit-box",
      "-webkit-line-clamp": "1",
      "-webkit-box-orient": "vertical"
    }
  }, [_vm._v(_vm._s(_vm.giftcard.name))]), _c('div', {
    staticClass: "mt-2 mb-8 gift-subtitle text-left primary--text",
    staticStyle: {
      "text-overflow": "ellipsis",
      "overflow": "hidden",
      "display": "-webkit-box",
      "-webkit-line-clamp": "1",
      "-webkit-box-orient": "vertical"
    }
  }, [_vm._v("권종 선택구매")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }