var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "540"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.order ? _c('v-card', {
    staticClass: "order-complete"
  }, [_c('v-card-title', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "title"
  }, [_c('b', [_vm._v("주문완료")])])]), _c('v-col', {
    staticClass: "text-end"
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-1 pb-3"
  }, _vm._l(_vm.order.purchases, function (purchase, index) {
    var _purchase$giftcard$th, _purchase$giftcard$th2;
    return _c('v-layout', {
      key: index,
      staticClass: "mt-2"
    }, [_c('v-img', {
      attrs: {
        "src": ((_purchase$giftcard$th = purchase.giftcard.thumb) === null || _purchase$giftcard$th === void 0 ? void 0 : _purchase$giftcard$th.path) || ((_purchase$giftcard$th2 = purchase.giftcard.thumb) === null || _purchase$giftcard$th2 === void 0 ? void 0 : _purchase$giftcard$th2.src),
        "max-width": "96",
        "max-height": "96"
      }
    }), _c('div', {
      staticClass: "px-4"
    }, [_c('b', [_vm._v(_vm._s(purchase.giftcard.name))]), _c('div', [_vm._v(_vm._s(purchase.giftcard.name))])]), _c('v-spacer'), _c('div', {
      staticClass: "d-flex align-center"
    }, [_c('div', {
      staticClass: "text-end"
    }, [_c('div', [_vm._v(_vm._s(purchase.quantity.format()) + " 개")]), _c('div', [_vm._v(_vm._s(purchase.giftcard.salePrice.format()) + " 원")])])])], 1);
  }), 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-1 pb-3"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "caption mr-2 mt-2"
  }, [_vm._v("주문번호")]), _c('div', [_vm._v(_vm._s(_vm.order.orderNo))])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "caption mr-2 mt-2"
  }, [_vm._v("주문일자")]), _c('div', [_vm._v(_vm._s(_vm.$dayjs(_vm.order.createdAt).format("YYYY-MM-DD HH:mm:ss")))])])], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "caption mr-2 mt-2"
  }, [_vm._v("주문금액")]), _c('div', [_vm._v(_vm._s(_vm.order.totalAmount.format()) + " 원")])])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-3"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "caption mr-2 mt-2"
  }, [_vm._v("구매자 이름")]), _c('div', [_vm._v(_vm._s(_vm.order.buyer.name))])])], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "caption mr-2 mt-2"
  }, [_vm._v("구매자 연락처")]), _c('div', [_vm._v(_vm._s(_vm.order.buyer.phone))])]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "caption mr-2 mt-2"
  }, [_vm._v("구매자 이메일")]), _c('div', [_vm._v(_vm._s(_vm.order.buyer.email))])])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-3"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "caption mr-2 mt-2"
  }, [_vm._v("결제방법")]), _c('div', [_vm._v("포인트 결제")])])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "blue"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("닫기")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }