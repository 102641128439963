var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "1200"
    }
  }, [_c('v-layout', [_vm._t("header")], 2), _c('v-layout', {
    staticClass: "mt-3 mt-lg-7 mx-n2 main-giftcards",
    attrs: {
      "wrap": ""
    }
  }, [_vm._l(_vm.giftcards, function (giftcard, index) {
    return [_c('v-flex', {
      key: index,
      staticClass: "px-2",
      attrs: {
        "xs6": "",
        "md3": ""
      }
    }, [_c('main-giftcard-item', {
      attrs: {
        "giftcard": giftcard
      }
    })], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }