<template>
    <div class="main-categories">
        <slot />
    </div>
</template>
<script>
export default {
    props: ["value"],
    data(){
        return {
            selected: this.$props.value
        }
    },
    watch: {
        value(){
            this.selected = this.$props.value;
        },
        selected(){
            this.$emit("input", this.selected);
        }
    }
}
</script>

<style scoped>
.main-categories {
    overflow: visible;
}
.main-categories .v-divider {
    border-color: white;
}
</style>