<script>
import { VTextField } from "vuetify/lib";
export default {
    name: "VNumberField",
    extends: VTextField,
    data(){
        return {
            inputValue: this.$props.value
        }
    },
    created(){
        this.$on("focus", (event) => {
            console.log("focus");
            console.log({ target: event?.target });
        });
        this.$on("keydown", (event) => {
            console.log("keydown");
            console.log(event);
            console.log({ target: event.target });
            
            if(event.key === '.'){
                let preChar = this.lazyValue?.substr?.(event.target.selectionEnd - 1, event.target.selectionEnd) ?? "";
                if(-1 < this.lazyValue.indexOf('.') || !preChar.match(/[0-9]/)) event.preventDefault();
            }

            if(event.key == "Backspace"){
                let target = event.target;
                let value = event.target.value;
                let preChar = this.lazyValue?.substr?.(event.target.selectionEnd - 1, event.target.selectionEnd) ?? "";

                if(preChar.match(/[0-9]/)){
                    this.lazyValue = event.target.value = value.substr(0, event.target.selectionEnd);
                }
            }
            else{
                let target = event.target;
                let value = target.value + event.key;
                target.value = (+value.replace(/[^0-9\.]/g, '')).format();
                this.lazyValue = target.value;
            }
            
            if(event.key != "ArrowLeft" && event.key != "ArrowRight") event.preventDefault();
        });

        this.$off("keyup");
        this.$on("keyup", (event) => {
            alert(1);
            console.log("keyup");
        })
    },
    methods: {
        input(){
            // alert(1);
        }
    },
    watch: {
        lazyValue(){
            
        }
    }
}
</script>