<template>
    <v-responsive class="giftcard-view">
        <v-row class="giftcard-info">
            <v-col cols="12" md="6" class="pa-3 pa-md-6">
                <v-responsive width="100%" :aspect-ratio="1/1" class="elevation-3">
                    <v-img :src="giftcard?.images?.[0]" width="100%" height="auto" contain />
                </v-responsive>
            </v-col>
            <v-col cols="12" md="6" class="pa-3 pa-md-6">
                <v-responsive width="100%">
                    <div class="giftcard-name">{{ giftcard?.name }}</div>
                    <v-divider class="my-3"/>
                    <v-row no-gutters>
                        <v-col cols="auto"><span><b>판매가</b></span></v-col>
                        <v-spacer/>
                        <v-col cols="auto">
                            <span v-if="giftcard?.price && giftcard?.price != giftcard?.salePrice" class="red--text text-decoration-line-through mr-2">{{ giftcard?.price?.format?.() }} 원</span>
                            <span><b>{{ giftcard?.salePrice?.format?.() }}</b> 원</span>
                        </v-col>
                    </v-row>
                    <v-divider class="my-3"/>
                    <v-text-field v-model="quantity" outlined label="구매수량" placeholder=" " hide-details="" suffix="개" maxlength="5" @input="$nextTick(_ => { quantity = quantity.replace(/[^0-9]/g, ''); quantity = 200 < quantity ? 200 : quantity; })"/>
                    <v-divider class="my-3"/>
                    <v-row no-gutters>
                        <v-col cols="auto"><span><b>구매금액</b></span></v-col>
                        <v-spacer/>
                        <v-col cols="auto"><span><b>{{ saleAmount.format() }}</b> 원</span></v-col>
                    </v-row>
                    <v-divider class="my-3"/>
                    <v-row no-gutters align="center">
                        <v-col cols="auto">
                            <span class="pink--text"><b>보유포인트</b></span>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="auto">
                            <span v-if="me"><b>{{ (me?.point || 0).format() }}</b> 원</span>
                            <v-btn v-else dark elevation="0" color="red darken-1" @click="login">로그인</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="me" no-gutters align="center">
                        <v-col cols="auto">
                            <span>나의 포인트충전 계좌</span>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="auto">
                            <span>[우리은행] {{ me.vaccount.accountNumber }}</span>
                        </v-col>
                    </v-row>
                    <v-divider v-if="me" class="my-2"/>
                    <v-row v-if="me" no-gutters align="center">
                        <v-col cols="12" class="caption">
                            <div>※위의 계좌로 입금시 1~3분 내로 포인트가 자동으로 충전됩니다.</div>
                            <div>※단 발급된 가상계좌 이외 고객님의 실수로 타 계좌 입금시 반영이 되지 않습니다.</div>
                        </v-col>
                    </v-row>
                    <v-divider class="my-3"/>
                    <v-row no-gutters>
                        <v-spacer/>
                        <v-col>
                            <v-btn width="100%" tile dark x-large color="grey darken-3" @click="order">주문요청</v-btn>
                        </v-col>
                    </v-row>

                </v-responsive>
            </v-col>
        </v-row>

        <div id="giftcard-content" class="giftcard-content mt-lg-6">
            <v-divider />
            <v-layout no-gutters>
                <v-btn width="50%" href="#giftcard-content" x-large tile outlined class="text-center">상품정보</v-btn>
                <v-btn width="50%" href="#giftcard-notification" x-large tile text class="text-center">안내사항</v-btn>
            </v-layout>
            <v-divider class="mb-3"/>
            <p v-html="giftcard?.content" class="contain"></p>
        </div>

        <div id="giftcard-notification" class="giftcard-notification mt-6">
            <v-divider />
            <v-layout no-gutters>
                <v-btn width="50%" href="#giftcard-content" x-large tile text class="text-center">상품정보</v-btn>
                <v-btn width="50%" href="#giftcard-notification" x-large tile outlined class="text-center">안내사항</v-btn>
            </v-layout>
            <v-divider class="mb-3"/>
            <p v-html="giftcard?.notification"></p>
        </div>

        <login-dialog ref="loginDialog" />
        <complete-dialog ref="completeDialog" />
    </v-responsive>
</template>
<script>
import api from "@/api";
import { mdiPlusCircleMultipleOutline } from "@mdi/js";
import LoginDialog from "@/components/client/login/login-dialog.vue";
import CompleteDialog from "@/components/client/gshop/order/complete-dialog.vue";
import VNumberField from "@/components/plugins/vuetify/v-number-field.vue";
export default {
    components: {
        LoginDialog,
        CompleteDialog,
        VNumberField
    },
    data(){
        return {
            mdiPlusCircleMultipleOutline,

            giftcard: null,
            saleAmount: 0,
            quantity: 0,
            me: null,
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { giftcard } = await api.v1.gshop.giftcards.get({ _id: this.$attrs._id || this.$attrs.code });
            this.giftcard = giftcard;

            this.quantity = 0;
            this.saleAmount = this.quantity * this.giftcard.salePrice;

            if(this.accessToken){
                let { me } = await api.v1.me.profile.get();
                this.me = me;
            }
        },
        validate(){
            try{
                if(parseInt(+this.quantity || 0) <= 0) throw new Error("구매수량을 입력해주세요. 구매수량은 최대 200개까지 가능합니다");

                let saleAmount = this.giftcard.salePrice * this.quantity;
                if(parseInt(+this.me?.point || 0) < saleAmount) throw new Error("보유 포인트가 부족합니다");
                
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async login(){
            return new Promise((resolve, reject) => {
                if(this.logon) resolve();
                else{
                    this.$refs.loginDialog.$off();
                    this.$refs.loginDialog.$on("login", _ => resolve());
                    this.$refs.loginDialog.$on("cancel", _ => reject());
                    this.$refs.loginDialog.open();
                }
            });
        },
        async order(){
            if(this.validate()){
                if(confirm(`[${this.giftcard.name}]을 ${(+this.quantity).format()}개 구매하시겠습니까`)){
                    this.login().then(async _ => {
                        let { cart } = await api.v1.me.gshop.carts.post({
                            giftcard: this.giftcard,
                            quantity: this.quantity,
                        });
        
                        let { order } = await api.v1.gshop.orders.post({
                            _carts: [cart._id]
                        });
        
                        this.$refs.completeDialog.order = order;
                        this.$refs.completeDialog.open();
    
                        let { me } = await api.v1.me.profile.get();
                        this.me = me;
                    });
                }
            }
        }
    },
    watch: {
        quantity(){
            this.saleAmount = this.quantity * this.giftcard.salePrice;
        },
        async accessToken(){
            if(this.accessToken){
                let { me } = await api.v1.me.profile.get();
                this.me = me;
            }
            else this.me = null;
        }
    },
    computed: {
        logon(){ return !!this.$store.state.accessToken; },
        logoff(){ return !this.$store.state.accessToken; },
        accessToken(){ return this.$store.state.accessToken },
    }
}
</script>

<style scoped>
.giftcard-view .giftcard-name {
    font-size: 32px;
    font-weight: 500;
}
.giftcard-view .giftcard-description {
    font-size: 13px;
}
.giftcard-view .giftcard-content >>> img {
    max-width: 100%;
    height: auto;
}
.giftcard-view .giftcard-notification >>> img {
    max-width: 100%;
    height: auto;
}
.giftcard-view >>> input {
    text-align: end;
}
</style>