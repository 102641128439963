<template>
    <v-dialog v-model="show" max-width="540">
        <v-card v-if="order" class="order-complete">
            <v-card-title>
                <v-row no-gutters>
                    <v-spacer/>
                    <v-col cols="auto"><span class="title"><b>주문완료</b></span></v-col>
                    <v-col class="text-end"><v-icon large @click="close">mdi-close</v-icon></v-col>
                </v-row>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pt-1 pb-3">
                <v-layout v-for="(purchase, index) in order.purchases" :key="index" class="mt-2">
                    <v-img :src="purchase.giftcard.thumb?.path || purchase.giftcard.thumb?.src" max-width="96" max-height="96" />
                    <div class="px-4">
                        <b>{{ purchase.giftcard.name }}</b>
                        <div>{{ purchase.giftcard.name }}</div>
                    </div>
                    <v-spacer/>
                    <div class="d-flex align-center">
                        <div class="text-end">
                            <div>{{ purchase.quantity.format()}} 개</div>
                            <div>{{ purchase.giftcard.salePrice.format()}} 원</div>
                        </div>
                    </div>
                </v-layout>
            </v-card-text>
            <v-divider/>
            <v-card-text class="pt-1 pb-3">
                <v-row no-gutters>
                    <v-col cols="6">
                        <div class="caption mr-2 mt-2">주문번호</div>
                        <div>{{ order.orderNo }}</div>
                    </v-col>
                    <v-col cols="6">
                        <div class="caption mr-2 mt-2">주문일자</div>
                        <div>{{ $dayjs(order.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6">
                        <div class="caption mr-2 mt-2">주문금액</div>
                        <div>{{ order.totalAmount.format() }} 원</div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider/>
            <v-card-text class="py-3">
                <v-row no-gutters>
                    <v-col cols="6">
                        <div class="caption mr-2 mt-2">구매자 이름</div>
                        <div>{{ order.buyer.name }}</div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6">
                        <div class="caption mr-2 mt-2">구매자 연락처</div>
                        <div>{{ order.buyer.phone }}</div>
                    </v-col>
                    <v-col cols="6">
                        <div class="caption mr-2 mt-2">구매자 이메일</div>
                        <div>{{ order.buyer.email }}</div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="py-3">
                <v-row no-gutters>
                    <v-col cols="6">
                        <div class="caption mr-2 mt-2">결제방법</div>
                        <div>포인트 결제</div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <!-- <v-btn text color="blue-grey" to="/member/orders">주문내역보기</v-btn> -->
                <v-spacer/>
                <v-btn text color="blue" @click="close">닫기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data(){
        return {
            show: false,

            order: null,
        };
    },
    methods: {
        init(){
            this.order = null;
        },
        close(){
            this.show = false;
            this.init();
        },
        open(){
            this.show = true;
        },
    }
}
</script>

<style scoped>
.order-complete .title {
    font-size: 24px !important;
}
.order-complete .caption {
    font-weight: 700 !important;
}
</style>