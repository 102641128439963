var render = function render(){
  var _vm$$parent;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "main-category-item",
    class: {
      'active': ((_vm$$parent = _vm.$parent) === null || _vm$$parent === void 0 ? void 0 : _vm$$parent.selected) == _vm.value
    },
    attrs: {
      "width": "150",
      "height": "150",
      "color": "grey lighten-4",
      "ripple": false,
      "elevation": "0"
    },
    on: {
      "click": function () {
        return _vm.$parent.selected = _vm.value;
      }
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }