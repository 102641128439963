<template>
    <v-responsive max-width="1200" class="mx-auto">
        <v-layout>
            <slot name="header" />
        </v-layout>

        <!-- 상품권 리스팅 -->
        <v-layout class="mt-3 mt-lg-7 mx-n2 main-giftcards" wrap>
            <template v-for="giftcard, index in giftcards">
                <v-flex xs6 md3 class="px-2" :key="index">
                    <main-giftcard-item :giftcard="giftcard" />
                </v-flex>
            </template>
        </v-layout>
    </v-responsive>
</template>

<script>
import api from "@/api";
import MainGiftcardItem from "./main-giftcard-item.vue";
export default {
    components: {
        MainGiftcardItem
    },
    props: ["filter", "to"],
    data(){
        return {
            limit: 20,

            giftcards: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            let { giftcards } = await api.v1.gshop.giftcards.gets({
                headers: {
                    limit: this.limit
                },
                params: {
                    type: "giftcard",
                    ...this.$props.filter
                }
            });

            this.giftcards = giftcards;
        }
    },
    watch: {
        "$props.filter": {
            deep: true,
            handler(){ this.search(); }
        }
    }
}
</script>