<template>
    <div class="boarc-items">
        <div class="board-items__title mb-3 mb-lg-6">
            <p>후기 및 문의</p>
            <v-btn outlined rounded class="more-button"><router-link to="/board/question">더보기</router-link></v-btn>
        </div>
        <div class="board-items__basic">
            <ul>
                <li v-for="board, index in boards" :key="index">
                    <router-link :to="`/board/question/${board._id}`">
                        <p class="notice-info">{{ board.subject }}<v-icon v-if="board.isSecret" size="12" class="ml-1">{{ mdiLock }}</v-icon></p>
                        <!-- <p class="notice-date">{{ hideName(board?.writer?.name) }}</p> -->
                        <p class="notice-date">{{ $dayjs(board.createdAt).format("YYYY-MM-DD") }}</p>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { mdiLock } from "@mdi/js";
export default {
    data(){
        return {
            mdiLock,

            boards: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { boards } = await api.v1.boards.gets({
                headers: {
                    limit: 10,
                },
                params: {
                    code: "question",
                }
            });

            this.boards = boards;
        },
        hideName(name){
            if(!name) return;
            return name.split('').map((char, index) => (index == 1 || (0 < index && index < name.length - 1)) ? "*" : char ).join('');
        }
    }
}
</script>