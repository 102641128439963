var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "boarc-items"
  }, [_c('div', {
    staticClass: "board-items__title mb-3 mb-lg-6"
  }, [_c('p', [_vm._v("자주묻는 질문")]), _c('v-btn', {
    staticClass: "more-button",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/center/faqs"
    }
  }, [_vm._v("더보기")])], 1)], 1), _c('div', {
    staticClass: "board-items__basic board-basic__line"
  }, [_c('div', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.faqs, function (faq, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_c('span', [_vm._v(_vm._s(faq.question))])]), _c('v-expansion-panel-content', [_vm._v(" " + _vm._s(faq.answer) + " ")])], 1);
  }), 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }