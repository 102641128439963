var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "boarc-items"
  }, [_c('div', {
    staticClass: "board-items__title mb-3 mb-lg-6"
  }, [_c('p', [_vm._v("공지사항")]), _c('v-btn', {
    staticClass: "more-button",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/center/notifications"
    }
  }, [_vm._v("더보기")])], 1)], 1), _c('div', {
    staticClass: "board-items__basic"
  }, [_c('ul', _vm._l(_vm.notifications, function (notification, index) {
    return _c('li', {
      key: index
    }, [_c('a', {
      attrs: {
        "href": "#"
      }
    }, [_c('p', {
      staticClass: "notice-info"
    }, [notification.isNotice ? _c('span', [_vm._v("[공지]")]) : _vm._e(), _vm._v(_vm._s(notification.subject))]), _c('p', {
      staticClass: "notice-date"
    }, [_vm._v(_vm._s(_vm.$dayjs(notification.createdAt).format("YYYY-MM-DD")))])])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }