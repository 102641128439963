var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "visual"
  }, [_vm.banner ? _c('div', {
    staticClass: "swiper-container",
    attrs: {
      "align-center": ""
    }
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_vm._l(_vm.banner.slides, function (slide, index) {
    return [_c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c(_vm.lazyComponent(slide.url), {
      tag: "component",
      staticClass: "background background--1",
      attrs: {
        "to": slide.url,
        "href": slide.url
      }
    }, [_c('img', {
      attrs: {
        "src": slide.image,
        "alt": ""
      }
    })])], 1)];
  })], 2), !_vm.hidePagination ? _c('div', {
    staticClass: "slide-pagination"
  }) : _vm._e()]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }