<template>
    <v-card width="150" height="150" color="grey lighten-4" class="main-category-item" :class="{'active': $parent?.selected == value}" :ripple="false" elevation="0" @click="() => $parent.selected = value">
        <slot />
    </v-card>
</template>
<script>
export default {
    props: ["value"],
}
</script>

<style scoped>
.main-category-item {
    display: flex;
    overflow: visible;
}
.main-category-item::before {
    width: 0;
    height: 0;
}
.main-category-item.active::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 12px solid #ffffff;
    border-bottom: 12px solid #ffffff;
    border-left: 12px solid #F5F5F5;
    position: absolute;
    left: unset;
    right: -11px;
    top: 50%;
    margin-top: -13px;
    background: #F5F5F5;
    opacity: 1;
}
</style>