var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "boarc-items"
  }, [_c('div', {
    staticClass: "board-items__title mb-3 mb-lg-6"
  }, [_c('p', [_vm._v("후기 및 문의")]), _c('v-btn', {
    staticClass: "more-button",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/board/question"
    }
  }, [_vm._v("더보기")])], 1)], 1), _c('div', {
    staticClass: "board-items__basic"
  }, [_c('ul', _vm._l(_vm.boards, function (board, index) {
    return _c('li', {
      key: index
    }, [_c('router-link', {
      attrs: {
        "to": `/board/question/${board._id}`
      }
    }, [_c('p', {
      staticClass: "notice-info"
    }, [_vm._v(_vm._s(board.subject)), board.isSecret ? _c('v-icon', {
      staticClass: "ml-1",
      attrs: {
        "size": "12"
      }
    }, [_vm._v(_vm._s(_vm.mdiLock))]) : _vm._e()], 1), _c('p', {
      staticClass: "notice-date"
    }, [_vm._v(_vm._s(_vm.$dayjs(board.createdAt).format("YYYY-MM-DD")))])])], 1);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }