<template>
    <div class="boarc-items">
        <div class="board-items__title mb-3 mb-lg-6">
        <p>공지사항</p>
        <v-btn outlined rounded class="more-button"><router-link to="/center/notifications">더보기</router-link></v-btn>
    </div>
    <div class="board-items__basic">
        <ul>
            <li v-for="notification, index in notifications" :key="index">
                <a href="#">
                    <p class="notice-info"><span v-if="notification.isNotice">[공지]</span>{{ notification.subject }}</p>
                    <p class="notice-date">{{ $dayjs(notification.createdAt).format("YYYY-MM-DD") }}</p>
                </a>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {
            notifications: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { notifications } = await api.v1.center.notifications.gets({
                header: {
                    limit: 5
                }
            });

            this.notifications = notifications;
        }
    }
}
</script>