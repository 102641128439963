<template>
    <div class="visual">
        <div v-if="banner" class="swiper-container" align-center>
            <div class="swiper-wrapper">
                <template v-for="(slide, index) in banner.slides">
                    <div class="swiper-slide" :key="index">
                        <component :is="lazyComponent(slide.url)" class="background background--1" :to="slide.url" :href="slide.url">
                            <img :src="slide.image" alt="" />
                        </component>
                    </div>
                </template>
            </div>
            <!-- <v-icon color="white" size="48" class="prev">{{ mdiChevronLeft }}</v-icon>
            <v-icon color="white" size="48" class="next">{{ mdiChevronRight }}</v-icon> -->
            <div v-if="!hidePagination" class="slide-pagination"></div>
        </div>
    </div>
</template>
<script>
import api from "@/api";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
export default {
    props: {
        code: { type: String, default: "" },
        hidePagination: { type: Boolean, default: false },
    },
    data() {
        return {
            mdiChevronLeft,
            mdiChevronRight,

            banner: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { banner } = await api.v1.common.banners.get({ code: this.code });
            this.banner = banner;

            this.render();
        },

        render() {
            this.$nextTick(() => {
                var visual = new window.Swiper(".visual .swiper-container", {
                    effect: "slide",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 700,
                    loop: 1 < this.banner?.slides?.length ? true : false,
                    pagination: {
                        el: ".visual .slide-pagination",
                        clickable: true,
                        type: "fraction",
                    },
                    breakpoints: {
                        769: {
                            // allowTouchMove: false,
                        },
                    },
                    // navigation: {
                    //     nextEl: '.visual .next',
                    //     prevEl: '.visual .prev'
                    // },
                    on: {
                        init: function () {
                            // IE Fade Effect
                            if (!this.el.classList.contains("swiper-container-initialized")) {
                                this.el.classList.add("swiper-container-initialized");
                            }
                        },
                    },
                });
            });
        },

        lazyComponent(url) {
            if (url?.indexOf("http") < 0 && url?.indexOf("https") < 0) return "router-link";
            return "a";
        },
    },
};
</script>

<style scoped>
.visual >>> img {
    max-width: 100%;
    max-height: 100%;
}
.visual >>> .swiper-container {
    position: relative;
}
.visual >>> .prev {
    z-index: 1;
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.visual >>> .next {
    z-index: 1;
    position: absolute;
    right: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.visual >>> .slide-pagination {
    z-index: 1;
    position: absolute;
    display: inline-block;
    bottom: 25px;
    left: 0;
    right: 0;
    width: fit-content;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 72px;
    width: 100%;
    border-radius: 50px;
    text-align: center;
    height: 25px;
}

.visual >>> .slide-pagination.swiper-pagination-fraction {
    color: #fff;
}

.visual >>> .slide-pagination.swiper-pagination-fraction span {
    font-weight: 300;
}

.visual >>> .slide-pagination.swiper-pagination-fraction .swiper-pagination-current {
    color: #ff3c5b;
    font-weight: 700;
}

.visual >>> .slide-pagination .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: white !important;
}

.visual.main-mo-banner {
    display: block;
}
.visual.main-pc-banner {
    display: none;
}

@media (min-width: 751px) {
    /*main visual banner*/
    .visual.main-mo-banner {
        display: none;
    }
    .visual.main-pc-banner {
        display: block;
    }
}
</style>
