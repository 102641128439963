<template>
    <v-card color="transparent" elevation="0" :to="`/gshop/giftcards/${giftcard._id}`">
        <div style="border:1px solid #e0e0e0;">
            <v-img :src="giftcard.thumb?.path || giftcard.thumb?.src" />
        </div>
        <div class="mt-4 gift-title text-left" style="text-overflow: ellipsis; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;">{{ giftcard.name }}</div>
        <div class="mt-2 mb-8 gift-subtitle text-left primary--text"  style="text-overflow: ellipsis; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;">권종 선택구매</div>
    </v-card>
</template>

<script>
export default {
    props: ["giftcard"]
}
</script>
<style>
    .gift-titl {font-size:18px;}
    .gift-subtitle {font-size:14px;}

    @media (min-width:1024px) {
        .gift-title {font-size:24px;}
        .gift-subtitle {font-size:16px;}
    }
</style>