<template>
    <div class="boarc-items">
        <div class="board-items__title mb-3 mb-lg-6">
            <p>자주묻는 질문</p>
            <v-btn outlined rounded class="more-button">
                <router-link to="/center/faqs">더보기</router-link>
            </v-btn>
        </div>
        <div class="board-items__basic board-basic__line">
            <div justify="center">
                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="faq, index in faqs" :key="index">
                        <v-expansion-panel-header>
                            <span>{{ faq.question }}</span></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ faq.answer }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {
            faqs: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            
            let { faqs } = await api.v1.center.faqs.gets({
                headers: {
                    limit: 5,
                },
            });

            this.faqs = faqs;
        }
    }
}
</script>